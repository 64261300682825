import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsletter-container',
  templateUrl: './newsletter-container.component.html',
  styleUrls: ['./newsletter-container.component.sass']
})
export class NewsletterContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
