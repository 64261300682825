import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';

import { Episode } from 'src/app/models/episode';
import { EpisodeService } from 'src/app/services/episode/episode.service';

@Component({
  selector: 'app-episode-container',
  templateUrl: './episode-container.component.html',
  styleUrls: ['./episode-container.component.sass']
})

export class EpisodeContainerComponent implements OnInit {

  episodes: Episode[];
  episodesCount: number = 0;

  constructor(private episodeService: EpisodeService) {}

  ngOnInit() {
    this.getEpisodes();
  }

  getEpisodes(): void {
    this.episodeService.getEpisodes()
    .subscribe(episodes => this.episodes = episodes.reverse());
  }
}
