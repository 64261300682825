import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Episode } from '../../models/episode'
import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class EpisodeService {
  private episodesUrl = 'http://127.0.0.1:8081/v1/episodes';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}) 
  };

  constructor(
    private http: HttpClient, 
    private episodeService: EpisodeService) { }

  getEpisodes(): Observable<Episode[]> {
    return this
            .http
            .get<Episode[]>(this.episodesUrl)
            .pipe(
                tap(episodes => this.log('fetched episodes', episodes)),
                catchError(this.handleError<Episode[]>('getEpisodes', []))
            );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      // this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    }
  }

  private log(message: string, episodes: Episode[]) {
    console.log(message)
    console.log(episodes)
  }
}