import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuContainerComponent } from './components/containers/menu-container/menu-container.component';
import { EpisodeContainerComponent } from './components/containers/episode-container/episode-container.component';
import { FilterContainerComponent } from './components/containers/filter-container/filter-container.component';
import { FooterContainerComponent } from './components/containers/footer-container/footer-container.component';
import { SocialContainerComponent } from './components/containers/social-container/social-container.component';
import { HomeContainerComponent } from './components/containers/home-container/home-container.component';
import { NewsletterContainerComponent } from './components/containers/newsletter-container/newsletter-container.component';
import { PolicyContainerComponent } from './components/containers/policy-container/policy-container.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuContainerComponent,
    EpisodeContainerComponent,
    FilterContainerComponent,
    FooterContainerComponent,
    SocialContainerComponent,
    HomeContainerComponent,
    NewsletterContainerComponent,
    PolicyContainerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
