import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EpisodeContainerComponent } from './components/containers/episode-container/episode-container.component';
import { HomeContainerComponent } from './components/containers/home-container/home-container.component';
import { PolicyContainerComponent } from './components/containers/policy-container/policy-container.component';


const routes: Routes = [
  {'path': '', 'component': HomeContainerComponent },
  {'path': 'privacypolicy', component: PolicyContainerComponent},
  {'path': 'episodes', component: EpisodeContainerComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
